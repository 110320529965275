$primary-color: #0d6efd;
$hover-color: #0b5ed7;
$disabled-color: #84b4ff;
$border-color: #e0e0e0;
$focus-shadow-color: rgba(0, 123, 255, 0.25);
$focus-border-color: #80bdff;
$background-color: #f8f9fa;
$text-color: #333333;

.login-container {
  width: 100%;
  max-width: 400px;
  background-color: white;
  border: 1px solid $border-color;
}

.form-control-lg {
  &:focus {
    box-shadow: 0 0 0 0.2rem $focus-shadow-color;
    border-color: $focus-border-color;
  }
}

.btn-primary {
  background-color: $primary-color;
  border: none;
  transition: background-color 0.2s ease;

  &:hover:not(:disabled) {
    background-color: $hover-color;
  }

  &:disabled {
    background-color: $disabled-color;
  }
}

body {
  background-color: $background-color;
}

.login-logo {
  max-width: 100px;
  height: auto;
  margin-right: 10px;
}

.img-responsive {
  display: inline-block;
}


.navbar-brand {
  font-family: "Century Gothic", sans-serif;
  font-size: 30px !important;
  font-weight: 700;
  color: $text-color !important;
  line-height: 45px;
  text-decoration: none;

  &:hover {
    color: $primary-color;
  }
}